import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { useLocation } from '@reach/router';
import favicon from '../assets/makadu_favicon.ico';

function SEO({ description, lang, meta, title }) {
  const { pathname } = useLocation();
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            defaultTitle: title
            defaultDescription: description
            author
            siteUrl: url
            defaultImage: image
          }
        }
      }
    `,
  );

  const {
    defaultTitle,
    defaultDescription,
    author,
    siteUrl,
    defaultImage,
  } = site.siteMetadata;

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    url: `${siteUrl}${pathname}`,
    image: `${siteUrl}${defaultImage}`,
    author,
  };

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | Makadu`}
      meta={[
        {
          name: `description`,
          content: seo.description,
        },
        {
          property: `og:title`,
          content: seo.title,
        },
        {
          property: `og:description`,
          content: seo.description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: seo.author,
        },
        {
          name: `twitter:title`,
          content: seo.title,
        },
        {
          name: `twitter:description`,
          content: seo.description,
        },
        {
          name: `image`,
          content: seo.image,
        },
        {
          name: `og:image`,
          content: seo.image,
        },
        {
          name: `twitter:image`,
          content: seo.image,
        },
        {
          name: `og:url`,
          content: seo.url,
        },
        {
          name: `keywords`,
          content:
            'Strony www poznań, Tworzenie stron www, Statyczne strony www Gastby, ' +
            'Strony www Gatsby js, Programowanie frontend React, Strony www Laravel, ' +
            'Aplikacje internetowe, Systemy internetowe, Dedykowane aplikacje internetowe, ' +
            'Sklepy internetowe, Projektowanie Logo poznań, Projektowanie identyfikacji wizualnej, ' +
            'Corporate Identity firmy, Budowanie marki, Copywriting, Materiały promocyjne',
        },
        {
          name: `google-site-verification`,
          content: 'X9jWTlMgQzkPpNrh0uaJtW2D8kZI69n5W4LzXG8wFkA',
        },
      ].concat(meta)}
    >
      <link rel="icon" href={favicon} />
    </Helmet>
  );
}

SEO.defaultProps = {
  title: null,
  lang: `pl`,
  meta: [],
  description: null,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
};

export default SEO;
